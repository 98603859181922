import '../css/admin.scss';

import 'jquery';

/* Bootstrap modules - comment out as required */
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'lazysizes';

import './argon.js';
import './components/CKReadMore';
import './components/ImageUploader';
import editor from './components/MapEditor';

(global as any).MapEditor = editor;


var editors = document.querySelectorAll(".html-form-control > textarea");
if (editors.length > 0)
{
    for (let i = 0; i < editors.length; i++)
    {
        const editor = editors[i] as HTMLTextAreaElement;
        CKEDITOR.inline(editor, {
            customConfig: '/site/ckconfig.js'
        });
    }
}