import * as $ from 'jquery';
import 'bootstrap/js/dist/modal';
import CropperJs from 'cropperjs/dist/cropper.esm';
import { Pica } from "pica";

const pica: () => Pica = require("pica");

let cropper: CropperJs;
let controls = new Array<Uploader>();

class Uploader
{
    private dropBox: Element = document.createElement("div");
    private preview: HTMLImageElement = document.createElement("img");
    private value: HTMLInputElement = document.createElement("input");
    private width = NaN;
    private height = NaN;
    private imageType = "image/png";

    private areaDragEnter(event: Event)
    {
        event.stopPropagation();
        event.preventDefault();
    }

    private areaDragLeave(event: Event)
    {
        event.stopPropagation();
        event.preventDefault();
    }

    private isDragEvent(ev: Event): ev is DragEvent
    {
        return (ev as DragEvent).dataTransfer !== undefined;
    }

    private areaDragOver(ev: Event)
    {
        if (!this.isDragEvent(ev) || ev.dataTransfer == null)
        {
            return false;
        }

        ev.stopPropagation();
        ev.preventDefault();

        ev.dataTransfer.dropEffect = "copy";

        return false;
    }

    private areaDrop(ev: Event)
    {
        if (!this.isDragEvent(ev) || ev.dataTransfer == null)
        {
            return false;
        }

        ev.stopPropagation();
        ev.preventDefault();

        if (ev.dataTransfer.files.length === 0)
        {
            return false;
        }
        else if (ev.dataTransfer.files.length === 1)
        {
            const item = ev.dataTransfer.files.item(0);
            if (item != null)
            {
                this.loadImageToEditor(item);
            }
        }
        else
        {
            alert("Please only drag one image onto the panel");
            return false;
        }

        return false;
    }

    private areaClick()
    {
        const input = document.createElement("input") as HTMLInputElement;
        input.type = "file";

        input.addEventListener("change",
            () =>
            {
                if (input.files)
                {
                    const item = input.files.item(0);
                    if (item != null)
                    {
                        this.loadImageToEditor(item);
                    }
                }
            });

        setTimeout(() => input.click(), 0);
    }

    private loadImageToEditor(image: File)
    {
        let isImageType: Boolean;
        switch (image.type)
        {
            case "image/jpeg":
            case "image/png":
            case "image/gif":
                isImageType = true;
                break;
            default:
                isImageType = false;
        }

        if (!isImageType)
        {
            return;
        }

        this.imageType = image.type;

        const reader = new FileReader();
        reader.addEventListener("load", (ev) => this.loadImageUriToEditor(ev));
        reader.readAsDataURL(image);
    }

    private loadImageUriToEditor(ev: any)
    {
        const cropDialog = $("#crop-dialog");
        const cropImage = $("#crop-image");
        cropImage.attr("src", ev.target.result);
        cropImage.data("index", GetIndexOfUploader(this));

        cropDialog.on("shown.bs.modal", () => SetupCropper(this.width, this.height));
        cropDialog.on("hide.bs.modal", () => CleanupCropper(this.width, this.height, this));

        cropDialog.modal("show");
    }


    private dataUriToBlob(dataUri: string)
    {
        let byteString: string;

        if (dataUri.split(",")[0].indexOf("base64") !== -1)
        {
            byteString = atob(dataUri.split(",")[1]);
        }
        else
        {
            byteString = decodeURI(dataUri.split(",")[1]);
        }

        const mimestring = dataUri.split(",")[0].split(":")[1].split(";")[0];

        const content = new Array();
        for (let i = 0; i < byteString.length; i++)
        {
            content[i] = byteString.charCodeAt(i);
        }

        return new Blob([new Uint8Array(content)], { type: mimestring });
    }

    public setImage(url: string)
    {
        this.preview.src = url;

        if (this.value.value.length === 36)
        {
            // it's probably a guid, submit it for cleanup
            $.ajax({
                // mixed form submit with data being data uri
                type: "GET",
                url: `/api/images/cleanup/${this.value.value}/`
            });
        }

        const formData = new FormData();
        formData.append("image", this.dataUriToBlob(url), "image.jpg");
        $.ajax({
            // mixed form submit with data being data uri
            type: "POST",
            url: "/api/images/upload/",
            data: formData as JQuery.PlainObject,
            cache: false,
            contentType: false,
            processData: false,
            success: (content: any) => this.value.value = content
        });
    }

    public getImageType()
    {
        return this.imageType;
    }

    public static setupFromContainer(elem: HTMLElement)
    {
        const uploader = new Uploader();

        uploader.dropBox = $(elem).find(".drop-area")[0];
        uploader.preview = $(elem).find(".image-preview > img")[0] as HTMLImageElement;
        uploader.value = $(elem).find("input[type=hidden]")[0] as HTMLInputElement;

        uploader.dropBox.addEventListener("dragenter", (ev: Event) => uploader.areaDragEnter(ev));
        uploader.dropBox.addEventListener("dragleave", (ev: Event) => uploader.areaDragLeave(ev));
        uploader.dropBox.addEventListener("dragover", (ev: Event) => uploader.areaDragOver(ev));
        uploader.dropBox.addEventListener("drop", (ev: Event) => uploader.areaDrop(ev));

        uploader.dropBox.addEventListener("click", () => uploader.areaClick());

        uploader.width = parseFloat($(elem).data("width"));
        uploader.height = parseFloat($(elem).data("height"));

        AddUploader(uploader);
    }
}

function rotateCropper(ev: Event, cropImage: CropperJs, degree: number)
{
    ev.preventDefault();
    cropImage.rotate(degree);
}

function scaleXCropper(ev: Event, cropImage: CropperJs, scaleX: number)
{
    ev.preventDefault();
    const data = cropImage.getData(false);

    const realScaleX = data.scaleX === -1
        ? 0 - scaleX
        : scaleX;

    cropImage.scaleX(realScaleX);
}

function scaleYCropper(ev: Event, cropImage: CropperJs, scaleY: number)
{
    ev.preventDefault();
    const data = cropImage.getData(false);

    const realScaleY = data.scaleY === -1
        ? 0 - scaleY
        : scaleY;

    cropImage.scaleY(realScaleY);
}

export function SetupCropper(width: number, height: number)
{
    const cropDiv = document.getElementById("crop-image") as HTMLImageElement;
    cropper = new CropperJs(cropDiv, {
        viewMode: 0,
        aspectRatio: width / height,
        autoCropArea: 1
    });

    const rotateCw = document.getElementById("crop-rotate-cw") as HTMLButtonElement;
    rotateCw.addEventListener("click", (ev) => rotateCropper(ev, cropper, 90));

    const rotateAcw = document.getElementById("crop-rotate-acw") as HTMLButtonElement;
    rotateAcw.addEventListener("click", (ev) => rotateCropper(ev, cropper, -90));

    const flipVert = document.getElementById("crop-flip-vert") as HTMLButtonElement;
    flipVert.addEventListener("click", (ev) => scaleYCropper(ev, cropper, -1));

    const flipHor = document.getElementById("crop-flip-hor") as HTMLButtonElement;
    flipHor.addEventListener("click", (ev) => scaleXCropper(ev, cropper, -1));
}

export function CleanupCropper(width: number, height: number, uploader: Uploader)
{
    const initialCanvas = cropper.getCroppedCanvas();

    if (!isNaN(height) && !isNaN(width))
    {
        const resizedCanvas = document.createElement("canvas");
        resizedCanvas.height = height;
        resizedCanvas.width = width;

        pica()
            .resize(initialCanvas, resizedCanvas)
            .then(result =>
            {
                uploader.setImage(result.toDataURL(uploader.getImageType()));
            });
    }
    else
    {
        uploader.setImage(initialCanvas.toDataURL(uploader.getImageType()));
    }

    cropper.destroy();

    const cropDialog = $("#crop-dialog");
    cropDialog.off("shown.bs.modal");
    cropDialog.off("hide.bs.modal");

    $("#crop-rotate-cw").off("click");
    $("#crop-rotate-acw").off("click");

    $("#crop-flip-vert").off("click");
    $("#crop-flip-hor").off("click");
}

export function GetIndexOfUploader(uploader: Uploader)
{
    return controls.indexOf(uploader);
}

export function AddUploader(uploader: Uploader)
{
    controls.push(uploader);
}

function Initialise()
{
    const uploaders = document.getElementsByClassName("field-image");
    for (let i = 0; i < uploaders.length; i++)
    {
        Uploader.setupFromContainer(uploaders[i] as HTMLElement);
    }
}

window.addEventListener("load", Initialise);